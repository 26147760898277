exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-portfolio-js": () => import("./../../../src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-templates-blog-details-js": () => import("./../../../src/templates/blog-details.js" /* webpackChunkName: "component---src-templates-blog-details-js" */),
  "component---src-templates-blogs-js": () => import("./../../../src/templates/blogs.js" /* webpackChunkName: "component---src-templates-blogs-js" */),
  "component---src-templates-careers-js": () => import("./../../../src/templates/careers.js" /* webpackChunkName: "component---src-templates-careers-js" */),
  "component---src-templates-case-study-js": () => import("./../../../src/templates/case-study.js" /* webpackChunkName: "component---src-templates-case-study-js" */),
  "component---src-templates-service-details-js": () => import("./../../../src/templates/service-details.js" /* webpackChunkName: "component---src-templates-service-details-js" */)
}

