/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

// You can delete this file if you're not using it
// import "@fontsource/open-sans/400.css" // Weight 400.
// import "@fontsource/open-sans/500.css" // Weight 500.
// import "@fontsource/open-sans/700.css" // Select either normal or italic.
// import "@fontsource/open-sans" // Defaults to weight 400 with normal variant.
import { initGoogleTag } from './src/utils/google-tag.js';

// Called when the Gatsby browser runtime first starts.
export const onClientEntry = () => {
  initGoogleTag();
};